.cost_card {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  padding: 35px 22px 0;
  min-height: 294px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.cost_card:hover {
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.15);
}
/* ready */
.ready_bg {
  background: linear-gradient(180deg, #f0910c 0%, #cb7d10 100%);
  border-radius: 20px;
  margin-bottom: -175px;
}
.index_1 {
  z-index: 1;
  position: relative;
}
.index_8 {
  z-index: 8;
  position: relative;
}
.email_box {
  padding: 14px 12px 11px 28px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 13px 31px -5px rgba(0, 0, 0, 0.13);
  border-radius: 4px !important;
}
.input_border {
  border: none !important;
  outline: none;
  padding: 10px 16px 10px 0px;
}
.login_hover:hover {
  color: var(--color-secondary);
  opacity: 0.8;
}
.btn_subscribe {
  height: 50px;
  padding: 11px 24px;
  background: var(--color-orange);
  border: 1px solid var(--color-orange);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.btn_subscribe:hover {
  box-shadow: 3px 4px 4px #cb7d10;
  transform: translateY(-6px);
}
.min_vh_fotr {
  min-height: 52vh;
}
.footer_logo_width {
  width: 100%;
}
.logo_fotr {
  margin-bottom: 70px;
}
.footer_margin_top {
  margin-top: 175px;
}

.w-170 {
  width: 170px;
}
/* contact */
.contact_box {
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.contact_input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
  outline: none;
  padding: 0px 0px 16px;
  color: #4d4d4d;
  min-height: 48px;
}
.contact_input::-webkit-resizer {
  display: none;
}
.w_49 {
  width: 49%;
}
.location_card {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
}
.border_radius_location {
  border-radius: 17px 0 0 17px;
}
/* partner_cards */
.aws_cards {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.03);
  border-radius: 14px;
  padding: 29px 27px 38px 27px;
  min-height: 239px;
  transition: all 0.3s ease-in-out;
}
.aws_cards:hover {
  transform: translateY(-10%);
}
.prtnr_bg {
  background-image: url("./assets/images/png/prtnr_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.engage_card {
  height: 474px;
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  padding: 16px;
}
.engage_card:hover {
  transform: translateY(-10%);
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.15);
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cost_card {
    padding: 30px 22px 0;
    min-height: 330px;
  }
}
@media (max-width: 1199.98px) {
  .ready_bg {
    margin-bottom: -155px;
  }
  .min_vh_fotr {
    min-height: 45vh;
  }
  .engage_card {
    height: 414px;
  }
}
@media (max-width: 991.98px) {
  .footer_logo_width {
    width: 75%;
  }
  .ready_bg {
    margin-bottom: -150px;
  }
  .engage_card:hover {
    transform: translateY(-10px);
  }
}
@media (max-width: 767.98px) {
  .min_vh_fotr {
    min-height: 40vh;
  }
  .ready_bg {
    margin-bottom: -145px;
  }
  .footer_margin_top {
    margin-top: 145px;
  }
  .email_box {
    padding: 5px 5px 5px 15px;
  }
  .btn_subscribe {
    padding: 7px 20px;
  }
}
@media (max-width: 575.98px) {
  .cost_card {
    padding: 20px;
    min-height: 250px;
    max-width: 296px;
    margin: auto;
  }
  .email_box {
    width: 100% !important;
  }
  .aws_cards {
    min-height: 220px;
    max-width: 300px;
    margin: auto;
  }
  .ready_bg {
    margin-bottom: -85px;
  }
  .footer_margin_top {
    margin-top: 95px;
  }
}
