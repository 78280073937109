@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
body {
  font-family: "Roboto", sans-serif !important;
}
:root {
  --font-xs: 12px;
  --font-sm: 16px;
  --font-md: 18px;
  --font-lg: 20px;
  --font-xl: 24px;
  --font-2xl: 45px;
  --font-3xl: 50px;
  --font-4xl: 60px;
  --font-5xl: 36px;
  --color-black: #000;
  --color-orange: #f0910c;
  --color-gray: #4d4d4d;
  --color-off-white: #f3f2ef;
  --color-gray002: #8c8c8c;
  --color-gray003: #4a4b4b;
  --color-light-gray: #808080;
  --color-gray004: #c9c9c9;
  --color-black002: #010101;
}
@media (max-width: 1399.98px) {
  :root {
    --font-xl: 23px;
    --font-2xl: 43px;
    --font-3xl: 48px;
    --font-4xl: 58px;
    --font-5xl: 34px;
  }
}
@media (max-width: 1199.98px) {
  :root {
    --font-sm: 14px;
    --font-2xl: 30px;
    --font-2xl: 32px;
    --font-3xl: 38px;
    --font-4xl: 50px;
  }
}
@media (max-width: 991.98px) {
  :root {
    --font-xl: 22px;
    --font-2xl: 28px;
    --font-3xl: 34px;
    --font-4xl: 42px;
  }
}
@media (max-width: 767.98px) {
  :root {
    --font-lg: 18px;
    --font-xl: 20px;
    --font-2xl: 25px;
    --font-3xl: 28px;
    --font-4xl: 31px;
    --font-5xl: 28px;
  }
}
@media (max-width: 575.98px) {
  :root {
    --font-sm: 14px;
    --font-md: 15px;
    --font-lg: 16px;
    --font-xl: 17px;
    --font-2xl: 22px;
    --font-3xl: 24px;
    --font-4xl: 30px;
    --font-5xl: 24px;
  }
}
.font_xs {
  font-size: var(--font-xs);
}
.font_sm {
  font-size: var(--font-sm);
}
.font_md {
  font-size: var(--font-md);
}
.font_lg {
  font-size: var(--font-lg);
}
.font_xl {
  font-size: var(--font-xl);
}
.font_2xl {
  font-size: var(--font-2xl);
}
.font_3xl {
  font-size: var(--font-3xl);
}
.font_4xl {
  font-size: var(--font-4xl);
}
.font_5xl {
  font-size: var(--font-5xl);
}
.color_black {
  color: var(--color-black);
}
.color_orange {
  color: var(--color-orange);
}
.color_gray {
  color: var(--color-gray);
}
.color_light_gray {
  color: var(--color-light-gray);
}
.color_gray002 {
  color: var(--color-gray002);
}
.color_gray003 {
  color: var(--color-gray003);
}
.color_gray004 {
  color: var(--color-gray004);
}
.color_black002 {
  color: var(--color-black002);
}
.color_offwhite {
  color: var(--color-off-white);
}
.bg_black {
  background-color: var(--color-black);
}
.bg_black {
  background-color: var(--color-black);
}
.bg_orange {
  background-color: var(--color-orange);
}
.bg_gray {
  background-color: var(--color-gray);
}
.bg_light_gray {
  background-color: var(--color-light-gray);
}
.bg_gray002 {
  background-color: var(--color-gray002);
}
.bg_gray003 {
  background-color: var(--color-gray003);
}
.bg_gray004 {
  background-color: var(--color-gray004);
}
.bg_black002 {
  background-color: var(--color-black002);
}
.bg_offwhite {
  background-color: var(--color-off-white);
}
.fw_medium {
  font-weight: 500;
}
/* ============================ BACK TO TOP CSS START ============================ */
#scroll {
  position: fixed;
  right: 2%;
  bottom: 3%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: none;
  border-radius: 50%;
  background-color: #f0910c;
  border: 1.6px solid #000;
  animation: up-down 2.5s ease-in-out infinite;
  z-index: 10;
}
@keyframes up-down {
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
#scroll span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -12px;
  height: 0;
  width: 0;
  border: 8px solid transparent;
  border-bottom-color: black;
}
#scroll:hover {
  -webkit-box-shadow: 0 0 17px rgba(0, 0, 0, 0.547);
  box-shadow: 0 0 17px rgba(0, 0, 0, 0.547);
}
#scroll:hover::after {
  height: 100%;
}
@media (min-width: 1200px) and (max-width: 1599.98px) {
  .modified_container {
    max-width: 1140px !important;
  }
}
.contact_hero {
  background-image: url("./assets/images/png/contact_hero.png");
}
.engagement_hero {
  background-image: url("./assets/images/png/engagement_hero.png");
}
.prtnr_bg {
  background-image: url("./assets/images/png/prtnr_bg.png");
}
.engagement_hero,
.contact_hero,
.prtnr_bg {
  min-height: 700px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.sticky_nav {
  z-index: 10;
}
@media (max-width: 1399.98px) {
  .engagement_hero,
  .contact_hero {
    min-height: 600px;
  }
}
@media (max-width: 1199.98px) {
  .engagement_hero,
  .contact_hero {
    min-height: 530px;
  }
}

/* =================== LOADER CSS =================== */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #ffffff;
  box-shadow: 9999px 0 0 -5px #ffffff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
  margin: 0 10px;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #ffffff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #ffffff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
  left: -20px;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #ffffff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
  left: 20px;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #ffffff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #ffffff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #ffffff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #ffffff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #ffffff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #ffffff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #ffffff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #ffffff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #ffffff;
  }
}

.Toastify__progress-bar--animated {
  background: #f0910c !important;
}

.Toastify--animate-icon svg {
  fill: #f0910c !important;
}

.text-error {
  color: red;
}

button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
