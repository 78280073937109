.nav-list-items li {
  cursor: pointer;
}
.navbar_hover {
  transition: all 0.3s ease-in-out;
}
.navbar_hover:hover {
  color: var(--color-orange) !important;
}
@media (max-width: 1199.98px) {
  .nav-list-items li {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.navbar-wrapper {
  z-index: 1;
}
.navbar-overlay {
  height: 0%;
  z-index: 10;
  top: -800px;
  left: -800px;
  background: #fff;
  transition: all 0.5s ease;
  border-radius: 0px 0px 100px 0px;
}
.active-nav-overlay .navbar-overlay {
  height: 100vh;
  top: 0;
  left: 0;
  transition: all 0.4s ease;
  border-radius: 0;
}
.active-nav-overlay {
  overflow: hidden;
}
.navbar-overlay li {
  font-size: 24px;
  font-weight: 400;
  padding-top: 15px;
  position: relative;
  cursor: pointer;
  transform: translateY(10px);
  transition: all 0.4s ease-in-out;
  opacity: 0;
  list-style-type: none;
}
.active-nav-overlay ul li:nth-child(1) {
  animation: animate_links 0.9s;
  animation-delay: 0.2s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.active-nav-overlay ul li:nth-child(2) {
  animation: animate_links 0.9s;
  animation-delay: 0.4s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.active-nav-overlay ul li:nth-child(3) {
  animation: animate_links 0.9s;
  animation-delay: 0.6s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.active-nav-overlay ul li:nth-child(4) {
  animation: animate_links 0.9s;
  animation-delay: 0.8s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes animate_links {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(-20px);
    opacity: 1;
  }
}
.fix-screen-height {
  height: 100vh;
  overflow: hidden;
}
.navbar-link {
  position: relative;
  transition: all 0.3s ease-in-out;
}
.navbar-link:hover {
  color: var(--color-light-green);
}
.hero_logo_mw {
  max-width: 200px;
}
@media (max-width: 991.98px) {
  #nav-icon1 {
    width: 35px;
    height: 23px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }
  #nav-icon1 span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background-color: #f0910c;
    border-radius: 7px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  #nav-icon1 span:nth-child(1) {
    top: 0px;
  }
  #nav-icon1 span:nth-child(2) {
    top: 9px;
  }
  #nav-icon1 span:nth-child(3) {
    top: 18px;
  }
  .active-nav-overlay #nav-icon1 {
    z-index: 20;
  }
  .active-nav-overlay #nav-icon1 span:nth-child(1) {
    top: 16px;
    transform: rotate(135deg);
  }
  .active-nav-overlay #nav-icon1 span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  .active-nav-overlay #nav-icon1 span:nth-child(3) {
    top: 16px;
    transform: rotate(-135deg);
  }
  .hero_logo_mw {
    max-width: 190px;
  }
}
/* --------------------------------- HOME-PAGE-HERO ----------------------------- */
.hero_min_height {
  min-height: 100vh;
}
.homehero_bg {
  background-image: url("./assets/images/png/hero-bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.common_btn {
  padding: 11px 24px;
  background: var(--color-orange);
  border: 1px solid var(--color-orange);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.common_btn:hover {
  color: var(--color-orange) !important;
  box-shadow: 300px 0px 0px inset #fff;
}
/* --------------------------------- HOME-PAGE-CARD -------------------------- */
.card_para_mw {
  max-width: 294px;
}
.lh_29 {
  line-height: 29px;
}
.hero_card {
  border-radius: 14px;
  height: 338px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.03);
  cursor: pointer;
}
.hero_card:hover {
  transform: translateY(-10%);
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.15);
}
.negative_margin_hero_cards {
  margin-top: -85px;
}
#contact_card {
  margin-top: -95px;
}
/* --------------------------------- TALENDED RESOURCES -------------------------- */
.talented_img_border {
  border: 7.22938px solid #fff6ea;
  border-radius: 12.6328px;
}
.amazone_connect_para_mw {
  max-width: 265px;
}
.mw_amazone_oracel {
  max-width: 200px;
}
/* --------------------------------- MEIA QUERY -------------------------- */
@media (max-width: 1199.98px) {
  .hero_card {
    height: 330px;
  }
  .mw_amazone_oracel {
    max-width: 220px;
  }
  .amazone_connect_para_mw {
    max-width: 391px;
  }
  .lh_29 {
    line-height: unset;
  }
}
@media (max-width: 991.98px) {
  .hero_card {
    height: 345px;
  }
  .hero_min_height {
    min-height: 630px;
  }
  .mw_amazone_oracel {
    max-width: 250px;
  }
  .negative_margin_hero_cards {
    margin-top: -113px;
  }
}
@media (max-width: 767.98px) {
  .negative_margin_hero_cards {
    margin-top: -90px;
  }
  .hero_card:hover {
    transform: translateY(0%);
  }
  .amazone_connect_para_mw {
    max-width: unset;
  }
  .hero_card {
    max-width: 330px;
    margin: auto;
  }
  .hero_card {
    height: 300px;
  }
}
@media (max-width: 575.98px) {
  .talented_img_border {
    max-width: 300px;
    margin: auto;
  }
}
